<template>

  <v-container>
    <v-row
      align="center"
      justify="center">
      <v-col v-if="isLoggedIn && success === null" xs="12" sm="10" md="8" xl="4">
        <v-alert
          type="error"
        >Sie sind bereits angemeldet.</v-alert>
      </v-col>
      <v-col v-if="!isLoggedIn" xs="12" sm="10" md="8" xl="4">
        <h1>Passwort setzen</h1>
        <v-card>
          <v-container>
            <v-row>
              <v-col v-if="!success">
                <v-form v-model="valid" action="POST" @submit.prevent="resetPW">
                    <v-row>
                      <v-col>
                        <v-text-field
                          name="password"
                          label="Passwort"
                          id="password"
                          type="password"
                          :rules="rules"
                          autocomplete="current-password"
                          v-model="authDetails.password"
                          required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          name="passwordConfirmation"
                          label="Passwort wiederholen"
                          id="passwordConfirmation"
                          type="password"
                          :rules="[(authDetails.password === authDetails.passwordConfirmation) || 'Die Passwörter stimmen nicht überein.']"
                          autocomplete="current-password"
                          v-model="authDetails.passwordConfirmation"
                          required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn color="accent" type="submit">Passwort setzen</v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                    <v-col v-if="error">
                      <v-alert
                        type="error"
                        style="white-space:pre-wrap"
                      >{{error}}</v-alert>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col v-if="success">
                <v-alert
                  type="success"
                  style="white-space:pre-wrap"
                >{{success}}</v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from 'graphql-tag';
import rules from '../../helpers/rules';

const RESET_PW = gql`
mutation resetPassword ($password: String! $passwordConfirmation: String! $code: String!){
  resetPassword(password: $password passwordConfirmation: $passwordConfirmation code: $code){
    jwt
    user {
        role {
            name
        }
    }
  }
}
`

export default {
  name: 'RequestPassword',
  valid: false,
  success: null,
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      valid: null,
      authDetails: {
        password: '',
        passwordConfirmation: '',
        code: ''
      },
      error: null,
      success: null,
      rules: rules.passRules,
    }
  },
  methods: {
    resetPW: function () {
      this.authDetails.code = this.$route.query.code;
      this.$apollo.mutate({mutation: RESET_PW, variables: { ...this.authDetails }}).then((data) => {
        //console.log(data);
        this.success = true;
        const token = 'Bearer ' + data.data.resetPassword.jwt;
        localStorage.setItem('token', token); //store JWT to local storage
        this.error = null; //clear error, if login failed previusly
        const resetPwRedirects = {
          appAdmin: '/vereine',
          verein: '/verein'
        };

        this.$router.push({path: resetPwRedirects[data.data.resetPassword.user.role.name] ? resetPwRedirects[data.data.resetPassword.user.role.name] : '/'}); // go to home
        this.$emit('changeLoginState'); //trigger login change event on App to change local login state

      }).catch((error) => {
        // Error
        let ermsg='';
        if(JSON.stringify(error).indexOf('Incorrect code provided.')!==-1){
          this.error = "Der Code ist nicht mehr gültig. bitte starten Sie den Prozess erneut."
        }else{
          ermsg = error;
          this.error = "Die Anfrage zum Zurücksetzen des Passworts hat einen Fehler verursacht. Bitte kontaktieren Sie ihren Administrator.\nFehlermeldung:\n"+ermsg;
        }
        console.error(error);

        //localStorage.setItem('token', '');
        //this.$emit('changeLoginState');
      });
    }
  }
}
</script>
